import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  //TODO: ADD YOUR FIREBASE PROJECT CONFIG HERE
  apiKey: "AIzaSyCZmnOZ729Axe65wATaei1YLQBhN8JJBpA",
  authDomain: "backend-399022.firebaseapp.com",
  databaseURL: "https://backend-399022-default-rtdb.firebaseio.com",
  projectId: "backend-399022",
  storageBucket: "backend-399022.appspot.com",
  messagingSenderId: "403636363956",
  appId: "1:403636363956:web:610792ef9023eaf1b86de1",
  measurementId: "G-V5WNRSL281",
};

export default firebaseConfig;
